/* Webfont: SuisseIntl-Regular */
@font-face {
    font-family: 'SuisseIntl-Regular';
    src: url('./assets/fonts/SuisseIntl-Regular-WebM.woff2'); /* Modern Browsers */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: SuisseIntl-SemiBold */
@font-face {
    font-family: 'SuisseIntl-SemiBold';
    src: url('./assets/fonts/SuisseIntl-SemiBold-WebM.woff2'); /* Modern Browsers */
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

html{
  padding: 0px;
  margin: 0px;
  border: 0px;
  font-size: 10px;
  font-family: 'SuisseIntl-Regular';
  color: #000;
  background-color: #fafafa;
  min-height: 100%;
  height: 100%;
}

html::-webkit-scrollbar {
  width: 0px;
}

html, body {
  overscroll-behavior-y: none;
}

:root{ scrollbar-width: none !important } /* Code for Firefox */

::-moz-selection { /* Code for Firefox */
  color: #000;
  background: #fafafa;
}

::selection {
  color: #000;
  background: #fafafa;
}
      
*{
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  cursor: default;
}

h1, h2, h3, h4, h5{
  font-weight: normal;
  font-family: 'SuisseIntl-SemiBold';
}

h1{ font-size: 4.8rem; }
h2{ font-size: 3.4rem; }
h3{ font-size: 2.4rem; }
h4{ font-size: 1.6rem; }

ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

a{
  font-family: 'SuisseIntl-Regular';
  /* text-decoration: none; */
  cursor: pointer;
}

button{
  font-family: 'SuisseIntl-Regular';
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

button:focus{
  outline: none;
}

.extraLarge {
  font-size: 2.8rem;
}

.large {
  font-size: 1.7rem;
  line-height: 2.2rem;
}

.normal {
  font-size: 1.4rem;
}

.small {
  font-size: 1.2rem;
}

.extraSmall {
  font-size: 1rem;
}

@media screen and (min-width: 1320px) {
  .extraLarge {
    font-size: 3.4rem;
  }

  .large {
    font-size: 2.1rem;
    line-height: 2.7rem;
  }

  .extraSmall {
    font-size: 1.3rem;
  }
}