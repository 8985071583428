.Grid__Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
}

.Grid__Wrapper .grid-col{
    width: 100%;
    flex: 1;
    text-align: center;
}

.Grid__Wrapper .Journal__Item {
    position: relative;
    width: 100%;
    display: block;
    margin: 0 auto 2rem;
}

.Grid__Wrapper .grid-col{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.Grid__Wrapper .grid-col:first-child{
    padding-bottom: 60px;
}

.Grid__Wrapper .grid-col::-webkit-scrollbar {
    display: none;
}

.Filters__Wrapper{
    position: fixed;
    bottom: 1rem;
    width: 100vw;
    
}

@media (min-width: 768px) {
    .Home{
        position: fixed;
    }   

    .Grid__Wrapper {
        flex-direction: row;
        overflow: hidden;
        height: 100vh;
    }

    .Grid__Wrapper .grid-col{
        flex: 1;
        text-align: center;
        width: fit-content;
        max-width: 280px;
        overflow: hidden;
        overflow-y: auto;
        margin: 0;
        padding: 36px 0 100px;
    }

    .Filters__Wrapper{
        bottom: 4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}