h2{
    font-size: 4rem;
    margin: 120px 10px 20px;
}

.Members__Wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 20px;
    max-width: 100vw;
}